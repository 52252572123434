<template>
    <div class="ns-login-wrap" v-loading="loadingAd">
        <!-- <h2>欢迎登录</h2> -->
        <div class="logo">
            <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
            <img v-else src="@/assets/images/logo.png" />
        </div>
        <el-form ref="ruleForm" class="login-form" :model="formData" :rules="accountRules">
            <el-form-item class="form-row" prop="account">
                <el-input type="text" autocomplete="off" placeholder="请输入您的手机号码/用户名" v-model="formData.account">
                    <!-- <template slot="prepend"><img src="~img/alibaba/phone.png" /></template> -->
                </el-input>
            </el-form-item>
            <el-form-item class="form-row" prop="password">
                <el-input type="password" autocomplete="off" placeholder="请输入密码" v-model="formData.password">
                    <!-- <template slot="prepend"><img src="~img/alibaba/lock.png" /></template> -->
                </el-input>
            </el-form-item>
            <!-- <el-form-item class="form-row" prop="vercode">
                <el-input type="text" autocomplete="off" placeholder="请输入验证码" v-model="formData.vercode">
                    <template slot="append"><img :src="captcha.img" mode class="captcha" @click="getCaptcha" /></template>
                </el-input>
            </el-form-item> -->
            <!-- <el-button @click="accountLogin('ruleForm')">立即登录</el-button> -->
        </el-form>

        <div class="login-bread-crumb">
            <el-breadcrumb separator=" ">
                <el-breadcrumb-item class="go-register" :to="{ path: '/register' }">马上注册会员</el-breadcrumb-item>
                <el-breadcrumb-item class="find-pass" :to="{ path: '/find_pass' }">忘记密码？</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="login-btn">
            <el-button @click="accountLogin('ruleForm')">登录</el-button>
        </div>
        <!-- <div class="copyright">
            <a>{{ copyRight.copyright_desc }}</a>
            <a v-if="copyRight.icp" href="https://beian.miit.gov.cn" target="_blank">{{ copyRight.icp }}</a>
            <a v-if="copyRight.gov_record" :href="copyRight.gov_url" target="_blank">{{ copyRight.gov_record }}</a>
        </div> -->
    </div>
</template>

<script>
import login from "./login"

export default {
    name: "login",
    mixins: [login]
}
</script>
<style lang="scss">
.login-master {
    background-image: url("~img/static/login.jpg");
    background-position: center;
    background-size: cover;
}
.ns-login-wrap {
    position: fixed;
    // right: 10%;
    left: 50%;
    margin-left: -240px;
    // margin: 0 auto ;
    width: 400px;
    bottom: 20%;
    background-color: #fff;
    padding: 0 40px;
	border-radius: 10px;
    h2 {
        color: $base-color;
        font-weight: 400;
        font-size: 24px;
    }

    .logo {
        height: 45px;
        margin: 0px auto;
        img {
            height: 90px;
        }
    }

    .login-form {
        margin-top: 60px;

        .form-row {
            padding: 10px 0;

            .el-input {
                display: flex;
                align-items: center;

                input {
                    width: 400px;
                    background-color: transparent !important;
                    // border: none;
                    // outline: none;

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-transition-delay: 111111s;
                        -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
                    }

                    &:-internal-autofill-selected {
                        background-color: transparent !important;
                    }
                }
            }

            .el-input-group__append {
                padding: 0;
                width: 120px;
                height: 38px;
            }

            .el-input-group__prepend {
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                box-sizing: border-box;
                background-color: transparent;

                img {
                    width: 16px;
                    height: 16px;
                }
            }

            .el-form-item__content {
                display: flex;
                align-items: center;
            }

            // border-bottom: 1px solid #d9d9d9;
            // margin-bottom: 30px;

            .form-row-val {
                &:before {
                    content: "";
                    height: 12px;
                    background-color: #d9d9d9;
                    margin-right: 10px;
                }

                input {
                    font-size: $ns-font-size-lg;
                    color: $ns-text-color-black;
                    border: none;
                    outline: none;
                    background-color: transparent !important;

                    &-internal-autofill-selected {
                        background-color: transparent !important;
                    }
                }
            }
        }

        .el-button {
            width: 180px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            border-radius: 25px;
            font-size: $ns-font-size-lg;
            margin-top: 60px;
            background-color: $base-color;
            border: none;
        }
    }

    .login-bread-crumb {
        margin-top: 25px;
        margin-left: 60px;
        .el-breadcrumb__item {
            span {
                color: $ns-text-color-gray;
                font-weight: 400;
                cursor: pointer;
            }

            &.go-register {
				margin-right: 100px;
                // span {
                //     color: $base-color;
                // }
            }
        }
    }

    .copyright {
        margin-top: 40px;

        a {
            color: $ns-text-color-gray;
            font-size: $ns-font-size-base;
        }
    }
    .login-btn {
        margin-left: 110px;
        .el-button {
            width: 180px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: $ns-font-size-lg;
            margin-top: 30px;
            margin-bottom: 40px;
            background-color: #009367;
            border: none;
            border-radius: 10px;
        }
    }
	.el-form-item {
		margin-bottom: 0px;
	}

}
</style>
